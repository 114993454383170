
import Vue from 'vue'
import Snackbar from './components/Snackbar/Snackbar.vue'
import { unSubFromFirebaseSnapshots } from './services'

export default Vue.extend({
  name: 'App',
  components: {
    Snackbar,
  },
  beforeDestroy() {
    unSubFromFirebaseSnapshots()
  },
})
