
import { Get, StructuralMember } from '@consteel/storm'
import DefaultLabel from './DefaultLabel.vue'
import { LabelBase, LabelContent } from './LabelBase'

export default (DefaultLabel as typeof LabelBase).extend({
  name: 'StructuralMemberLabel',
  computed: {
    currentMember(): StructuralMember | null {
      const member = Get<StructuralMember>(this.model, this.objectGuid, StructuralMember)
      return member
    },
    title(): string | undefined {
      return this.currentMember?.name
    },
    content(): LabelContent[] {
      return [
        { name: this.$t('Section'), value: this.currentMember?.section?.name },
        { name: this.$t('Material'), value: this.currentMember?.material?.name },
      ]
    },
  },
})
