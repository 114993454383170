import { OnStockWarehouseOptimization } from './OnStockWarehouseOptimization.model'
import { OnStockWarehouseSummaryItemBlock } from './OnStockWarehouseSummaryItemBlock.model'

export enum SummaryType {
  Section = 'SECTION',
  Material = 'MATERIAL',
}

export type OnStockWarehouseSummaryItem = {
  id: number
  type: SummaryType
  name: string
  quantity: number
  length: number
  warehouseOptimizationId: number
  warehouseOptimization: OnStockWarehouseOptimization
  warehouseSummaryItemBlocks: OnStockWarehouseSummaryItemBlock[]
}
