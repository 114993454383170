import { doRequest, downloadFile } from '../../../services/requests/Base'
import { SteelspaceResponse } from '../../../types'
import { OnStockWarehouseOptimization } from '../types'
import { OnStockPortionInfo } from '../types/OnStockSupplyOptimization/OnStockPortionInfo.model'

export const createWarehouseOptimizationRequest = async (
  projectId: string,
  modelId: string,
  warehouseCatalog: File,
  modelFile: File,
  tolerance: number,
  method: number,
  mainPriority: number,
  secondaryPriority: number,
  mainPriorityOrder: number,
  secondaryPriorityOrder: number,
  managerRuleIds: number[],
  preferExactMatchesChecked: boolean,
  portions: OnStockPortionInfo[]
): Promise<OnStockWarehouseOptimization | undefined> => {
  const formData = new FormData()
  formData.append('Model', modelFile)
  formData.append('WarehouseCatalog', warehouseCatalog)
  formData.append('Tolerance', tolerance.toString())
  formData.append('MatchingMethod', method.toString())
  formData.append('MainPriority', mainPriority.toString())
  formData.append('SecondaryPriority', secondaryPriority.toString())
  formData.append('MainPriorityOrder', mainPriorityOrder.toString())
  formData.append('SecondaryPriorityOrder', secondaryPriorityOrder.toString())
  formData.append('PreferExactMatches', preferExactMatchesChecked.toString())
  managerRuleIds.forEach((managerRuleId) =>
    formData.append('ManagerRuleIds[]', managerRuleId.toString())
  )
  formData.append('Portions', JSON.stringify(portions))

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/optimization/warehouse`,
    'POST',
    formData,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    false
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    const result: OnStockWarehouseOptimization = <OnStockWarehouseOptimization>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const exportWarehouseOptimizationRequest = async (
  projectId: string,
  modelId: string,
  warehouseOptimizationId: string
): Promise<void> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/optimization/warehouse/${warehouseOptimizationId}/export`,
    'GET',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    false
  )

  if (response.status === 200) {
    downloadFile(await response.blob(), `megfeleltés_eredmény_export.xlsx`)
  }
}

export const deleteWarehouseObjectsByMatchedWarehouseObjectRequest = async (
  projectId: string,
  modelId: string,
  warehouseId: string,
  warehouseObjectId: string
): Promise<boolean> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/optimization/warehouse/${warehouseId}/warehouse-object/${warehouseObjectId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    false
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
    return false
  }
}
export const deleteObjectMatchesBasedOnSummaryItemRequest = async (
  projectId: string,
  modelId: string,
  warehouseId: string,
  warehouseSummaryId: string
): Promise<boolean> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/optimization/warehouse/${warehouseId}/summaries/${warehouseSummaryId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    false
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
    return false
  }
}
export const deleteObjectMatchRequest = async (
  projectId: string,
  modelId: string,
  warehouseId: string,
  objectMatchId: string
): Promise<boolean> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/optimization/warehouse/${warehouseId}/object-matches/${objectMatchId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    false
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
    return false
  }
}
