var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        "default-label",
        "px-4",
        "py-2",
        { ..._vm.themeClasses, open: _vm.open, visible: _vm.visibility },
      ],
      style: `--scale: ${_vm.scale};`,
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "default-label-header",
          attrs: { justify: "flex-start" },
        },
        [
          _c(
            "cs-flex",
            {
              staticClass: "default-label-heder-title",
              on: {
                click: function ($event) {
                  _vm.open = !_vm.open
                },
              },
            },
            [
              _c("chevron-down-svg", { staticClass: "mr-2" }),
              _c("span", { staticClass: "default-label-heder-text" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ],
            1
          ),
          _c(
            "cs-flex",
            {
              staticClass: "default-label-heder-close",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_c("x-svg")],
            1
          ),
        ],
        1
      ),
      _c(
        "cs-flex",
        { staticClass: "default-label-content mt-1", attrs: { vertical: "" } },
        [
          _c(
            "cs-grid",
            {
              attrs: {
                "template-columns": "repeat(2, auto)",
                justify: "left",
                gap: "0 1rem",
              },
            },
            [
              _vm._l(_vm.content, function (item, id) {
                return [
                  item.name
                    ? _c("span", { key: id }, [_vm._v(_vm._s(item.name))])
                    : _vm._e(),
                  item.value
                    ? _c(
                        "div",
                        { key: id + _vm.content.length },
                        [
                          _c("span", [_vm._v(_vm._s(item.value))]),
                          _c(
                            "cs-latex-field",
                            { style: { "margin-left": "5px" } },
                            [_vm._v(_vm._s(item.unit))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }