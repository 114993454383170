import { DtoBase } from '@/types/Dtos/DtoBase'

export class AddShareDto extends DtoBase {
  constructor(
    email: string,
    canViewModel: boolean,
    canViewResult: boolean,
    canDownloadModel: boolean,
    canDownloadResult: boolean,
    canComment: boolean
  ) {
    super({ email, canDownloadModel, canDownloadResult, canViewModel, canViewResult, canComment })
  }
}
