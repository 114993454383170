import { doRequest } from '../../../services/requests'
import { SteelspaceResponse } from '../../../types'
import { AddShareDto, ModifyShareDto, RemoveShareDto, SteelspaceModelUserShare } from '../types'

export const addShareRequest = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  const dto = new AddShareDto(
    share.user.email,
    share.canViewModel,
    share.canViewResult,
    share.canDownloadModel,
    share.canDownloadResult,
    share.canComment
  )
  const response = await doRequest(`v2/users/me/models/${modelId}/share`, 'POST', dto)
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}
export const modifyShareRequest = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  const dto = new ModifyShareDto(
    share.user.email,
    share.canViewModel,
    share.canViewResult,
    share.canDownloadModel,
    share.canDownloadResult,
    share.canComment
  )
  const response = await doRequest(`v2/users/me/models/${modelId}/share`, 'PUT', dto)
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}
export const removeShareRequest = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  const dto = new RemoveShareDto(share.user.email)
  const response = await doRequest(`v2/users/me/models/${modelId}/share`, 'DELETE', dto)
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}
