import { Unsubscribe } from 'firebase/auth'
import Vue from 'vue'
import { ModelStateNumber } from '../../store/modelViewer.store'
import { Comment, CommentResponse, Unread } from './types'

const state = Vue.observable({
  progress: 0,
  unSubs: {} as Record<string, Unsubscribe>,
  comments: [] as Comment[],
  commentsSecondary: null as Comment[] | null,
  responses: {} as Record<string, CommentResponse[]>,
  unreads: [] as Unread[],
  error: '',
})

export const getCommentsBasedOnModelCompareState = (
  compareState: ModelStateNumber
): Comment[] | null => {
  return compareState === ModelStateNumber.primary ? state.comments : state.commentsSecondary
}

export const setCommentsBasedOnModelCompareState = (
  value: Comment[],
  compareState: ModelStateNumber
): void => {
  compareState === ModelStateNumber.primary
    ? (state.comments = value)
    : (state.commentsSecondary = value)
}

export const setCommentsSecondary = (value: Comment[] | null): void => {
  state.commentsSecondary = value
}

export const increaseCommentProgress = (): void => {
  state.progress += 1
}

export const decreaseCommentProgress = (): void => {
  state.progress -= 1
}

export const getCommentLoading = (): boolean => {
  return state.progress !== 0
}

export const setCommentUnSubs = (value: Record<string, Unsubscribe>): void => {
  state.unSubs = value
}

export const resetCommentState = (): void => {
  state.progress = 0
  state.unSubs = {}
  state.comments = []
  state.commentsSecondary = null
  state.responses = {}
  state.unreads = []
  state.error = ''
}

export const setCommentComments = (comments: Comment[]): void => {
  state.comments = comments
}

export const setCommentResponses = (responses: Record<string, CommentResponse[]>): void => {
  state.responses = responses
}

export const setCommentUnreads = (unreads: Unread[]): void => {
  state.unreads = unreads
}

export const setCommentError = (errorMsg: string): void => {
  state.error = errorMsg
}

export const commentStore = state as Readonly<typeof state>
