import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { authStore } from '../modules/auth'

export const createHub = async (url: string): Promise<HubConnection> => {
  const hubUrl = (process.env.VUE_APP_ONSTOCK_API_BASEURL || '') + url
  const connection = new HubConnectionBuilder()
    .withUrl(hubUrl, {
      accessTokenFactory: () => authStore.onStockToken?.token || '',
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  return connection
}

export const connectionOn = (
  connection: HubConnection,
  topic: string,
  callback: (...args: any[]) => any
): void => {
  connection.on(topic, callback)
}

export const connectionOff = (connection: HubConnection, topic: string): void => {
  connection.off(topic)
}

export const stopHub = async (connection: HubConnection): Promise<void> => {
  await connection.stop()
}

export const startHub = async (connection: HubConnection): Promise<void> => {
  try {
    await connection.start()
    console.log('SignalR Connected.')
  } catch (err) {
    console.log(err)
    setTimeout(startHub, 5000)
  }
}
