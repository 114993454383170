import { initializeAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_APIKEY,
  authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECTID,
  storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FB_APPID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENTID,
}

const firebasePlugin = {
  // eslint-disable-next-line
  install: (Vue, callback): void => {
    const firebaseApp = initializeApp(firebaseConfig)
    initializeAnalytics(firebaseApp)
    onAuthStateChanged(getAuth(), callback)
  },
}

export { firebasePlugin }
