import { authStore, loginOnStock, setOnStockToken } from '@/modules/auth'
import { onStockLoginRequest } from '@/modules/auth/requests'
import { DtoBase, HTTPMethod } from '@/types'
import { getAuth } from '@firebase/auth'

const getIdToken = async (isOnStock: boolean): Promise<string | undefined> => {
  if (isOnStock) {
    if (authStore.onStockToken) {
      const isOnstockTokenExpired = new Date(authStore.onStockToken.expires).getTime() < Date.now()

      if (isOnstockTokenExpired) {
        const onstockToken = await onStockLoginRequest()
        if (!onstockToken) return

        setOnStockToken(onstockToken) // refresh onstock token
      }
    } else {
      await loginOnStock()
    }

    return authStore.onStockToken?.token
  }

  const token = await getAuth().currentUser?.getIdToken()
  return token
}

export const doRequest = async (
  endpoint: string,
  method: HTTPMethod,
  dto?: DtoBase | FormData,
  baseUrl?: string,
  isOnStock = false,
  json = true
): Promise<Response> => {
  const baseUrl_ = baseUrl || process.env.VUE_APP_FB_API_BASEURL || ''

  const token = await getIdToken(isOnStock)

  const response = await fetch(`${baseUrl_}/${endpoint}`, {
    method,
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      ...(json ? { 'Content-Type': 'application/json' } : undefined),
    },
    body: json ? (dto as DtoBase)?.stringify() : (dto as FormData),
  })

  return response
}

export const downloadFile = async (blob: Blob, filename: string): Promise<void> => {
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = filename
  a.click()
}
