var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: { color: "red", right: true },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { color: "white", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.showSnackBar = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Bezárás ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showSnackBar,
        callback: function ($$v) {
          _vm.showSnackBar = $$v
        },
        expression: "showSnackBar",
      },
    },
    [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }