export const formatSize = (size: number): string => {
  const filesizeInMegabytes = size / 1024
  if (filesizeInMegabytes < 1) {
    return (
      Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2,
      }).format(size) + ' kB'
    )
  }

  const filesizeInGigabytes = filesizeInMegabytes / 1024
  if (filesizeInGigabytes < 1) {
    return (
      Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2,
      }).format(filesizeInMegabytes) + ' MB'
    )
  }

  return (
    Intl.NumberFormat(undefined, { maximumFractionDigits: 2 }).format(filesizeInGigabytes) + ' GB'
  )
}
