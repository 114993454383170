import { FolderType, HydratedSteelspaceFolder, SteelspaceFolder } from '@/modules/folder'
import { SteelspaceModel } from '@/modules/model'

export type FileTreeNode = {
  id: string
  name: string
  children: FileTreeNode[]
}

export type Ordering = 'alphabetical' | 'recent'

export const hydrate = (
  folders: SteelspaceFolder[],
  models: SteelspaceModel[],
  folderTree: FolderType,
  ordering?: Ordering
): HydratedSteelspaceFolder | undefined => {
  const mapFolderIdToFolder = buildFolderMap(folders, models, ordering)

  if (!mapFolderIdToFolder.size) {
    return undefined
  }

  return [...mapFolderIdToFolder.values()].find(
    (folder: HydratedSteelspaceFolder) => folder.isRoot && folder.type === folderTree
  )
}

export const convertHydratedToDirectoryTree = (
  rootFolder: HydratedSteelspaceFolder | undefined
): FileTreeNode | undefined => {
  if (!rootFolder) return undefined
  return convertHydratedFolderToFileTreeNode(rootFolder)
}

export const getHydratedFolder = (
  folders: SteelspaceFolder[],
  models: SteelspaceModel[] | null,
  publicModels: SteelspaceModel[] | null,
  folderId: string,
  ordering: Ordering
): HydratedSteelspaceFolder | undefined => {
  if (folderId === 'public') {
    const publicRoot = new SteelspaceFolder() as HydratedSteelspaceFolder
    publicRoot.name = 'Public models'
    publicRoot.type = 'public'
    publicRoot.isRoot = true
    publicRoot.childFolders = []
    publicRoot.childModels = publicModels as SteelspaceModel[]

    return publicRoot
  }

  const mapFolderIdToFolder = buildFolderMap(folders, models, ordering)

  return mapFolderIdToFolder.get(folderId)
}

export const getPathToFolder = (
  folders: SteelspaceFolder[],
  folderId: string
): SteelspaceFolder[] => {
  const path: SteelspaceFolder[] = []

  const mapFolderIdToFolder = buildFolderMap(folders)

  let folder = mapFolderIdToFolder.get(folderId)
  while (folder) {
    path.unshift(folder)
    folder = mapFolderIdToFolder.get(folder.parentId)
  }

  return path
}
export const searchInFolder = (
  folder: HydratedSteelspaceFolder,
  keyword: string
): HydratedSteelspaceFolder => {
  const folderCopy = folder.clone()
  folderCopy.childFolders = folderCopy.childFolders.filter((folder) =>
    folder.name.toLowerCase().includes(keyword.toLowerCase())
  )
  folderCopy.childModels = folderCopy.childModels.filter((model) =>
    model.name.toLowerCase().includes(keyword.toLowerCase())
  )
  return folderCopy
}

const buildFolderMap = (
  folders: SteelspaceFolder[],
  models?: SteelspaceModel[] | null,
  ordering?: Ordering
): Map<string, HydratedSteelspaceFolder> => {
  const mapFolderIdToFolder = new Map<string, HydratedSteelspaceFolder>(
    folders.map((folder) => [
      folder.id,
      new HydratedSteelspaceFolder({ ...folder, childFolders: [], childModels: [] }),
    ])
  )

  if (models && models.length > 0) {
    models.forEach((model) => {
      const parentFolder = mapFolderIdToFolder.get(model.parentId)
      if (parentFolder) {
        parentFolder.childModels.push(model)
      }
    })
  }

  mapFolderIdToFolder.forEach((folder) => {
    const parentFolder = mapFolderIdToFolder.get(folder.parentId)
    if (parentFolder) {
      parentFolder.childFolders.push(folder)
    }
  })

  // TODO: This whole sorting is a mess, should rewrite at some point
  if (ordering) {
    const sortingAttribute = (ordering: Ordering, type: 'file' | 'folder'): string => {
      switch (ordering) {
        case 'recent':
          switch (type) {
            case 'file':
              return 'lastModificationDate'
            case 'folder':
            default:
              return 'lastOpenDate'
          }
        case 'alphabetical':
        default:
          return 'name'
      }
    }
    const sortingDirection = (ordering: Ordering): 'asc' | 'desc' => {
      switch (ordering) {
        case 'recent':
          return 'desc'
        case 'alphabetical':
        default:
          return 'asc'
      }
    }

    const sortFunction = (
      a: any, // eslint-disable-line
      b: any, // eslint-disable-line
      sortingAttribute: string,
      sortingDirection: 'asc' | 'desc'
    ): number => {
      if (a[sortingAttribute] == b[sortingAttribute]) {
        return 0
      }

      let sortFlag = a[sortingAttribute].toString().localeCompare(b[sortingAttribute].toString())

      if (sortingDirection === 'desc') {
        sortFlag *= -1
      }

      return sortFlag
    }

    mapFolderIdToFolder.forEach((folder) => {
      folder.childFolders.sort((a: SteelspaceFolder, b: SteelspaceFolder) =>
        sortFunction(a, b, sortingAttribute(ordering, 'folder'), sortingDirection(ordering))
      )
      folder.childModels.sort((a: SteelspaceModel, b: SteelspaceModel) => {
        return sortFunction(a, b, sortingAttribute(ordering, 'file'), sortingDirection(ordering))
      })
    })
  }

  return mapFolderIdToFolder
}

const convertHydratedFolderToFileTreeNode = (
  folder: HydratedSteelspaceFolder | undefined
): FileTreeNode | undefined => {
  if (folder) {
    const children: FileTreeNode[] = []
    folder.childFolders.forEach((childFolder) => {
      const childNode = convertHydratedFolderToFileTreeNode(childFolder)
      if (childNode) {
        children.push(childNode)
      }
    })
    return {
      id: folder.id,
      name: folder.name,
      children: children,
    }
  }

  return undefined
}
