export function setCookie(
  cookieName: string,
  value: string | boolean | number,
  expirationDays: number
): void {
  const d = new Date()
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cookieName + '=' + value + ';' + expires + ';path=/'
}

export function getCookie(cookieName: string): string | undefined {
  const name = cookieName + '='
  const cookieArray = document.cookie.split(';')

  for (let i = 0; i < cookieArray.length; i++) {
    const current = cookieArray[i].trimStart()

    if (current.indexOf(name) == 0) {
      return current.substring(name.length, current.length)
    }
  }
  return undefined
}
