import { authStore, login, loginOauth, loginOnStock } from '@/modules/auth'
import { getAuth, signOut } from 'firebase/auth'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

//prettier-ignore
const AccountProfile = () => import(/* webpackChunkName: "account" */ '@/components/Account/AccountProfile.vue')
//prettier-ignore
const Collaborators = () => import(/* webpackChunkName: "account" */ '@/components/Account/Collaborators.vue')
//prettier-ignore
const AccountView = () => import(/* webpackChunkName: "account" */ '@/views/AccountView.vue')
//prettier-ignore
const ConsteelSignIn = () => import(/* webpackChunkName: "signin" */ '@/views/ConsteelSignIn.vue')
//prettier-ignore
const Explorer = () => import(/* webpackChunkName: "explorer" */ '@/views/Explorer.vue')
//prettier-ignore
const Landing = () => import(/* webpackChunkName: "landing" */ '@/views/Landing.vue')
//prettier-ignore
const ModelViewer = () => import(/* webpackChunkName: "modelviewer" */ '@/views/ModelViewer.vue')
//prettier-ignore
const ModelViewerTab = () => import(/* webpackChunkName: "modelViewerTab" */ '@/components/Viewer/ModelViewerTab.vue')
//prettier-ignore
const HistoryCompareTab = () => import(/* webpackChunkName: "historyCompareTab" */ '@/components/Viewer/HistoryCompareTab.vue')
//prettier-ignore
const HistoryCompareFooter = () => import(/* webpackChunkName: "historyCompareFooter" */ '@/components/Viewer/HistoryCompareFooter.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/sso',
    name: 'Sign in with Consteel',
    component: ConsteelSignIn,
  },
  {
    path: '/explorer/:projectId?',
    name: 'Explorer',
    component: Explorer,
    meta: { requiresAuth: true },
  },
  {
    path: '/model/:modelId',
    name: 'ModelViewer',
    component: ModelViewer,
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'Model', component: ModelViewerTab },
      {
        path: 'compare',
        name: 'Compare',
        components: { default: HistoryCompareTab, footer: HistoryCompareFooter },
      },
      {
        path: '/model/:modelId/history/:historyId',
        name: 'ModelHistory',
        component: ModelViewerTab,
      },
      {
        path: '/model/:modelId/history',
        redirect: { name: 'Model' },
      },
    ],
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView,
    meta: { requiresAuth: true },
    redirect: '/account/profile',
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: AccountProfile,
      },
      {
        path: 'collaborators',
        name: 'Collaborators',
        component: Collaborators,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const redirectUrlKey = 'redirectUrl'
  const redirectUrl = sessionStorage.getItem(redirectUrlKey)

  // consteelToken megfelel az sso idTokennel
  if (to.query.consteelToken) {
    const token = to.query.consteelToken.toString()

    const newQuery = to.query
    delete newQuery.consteelToken
    await loginOauth(token)
    router.replace({ path: to.path, hash: to.hash, query: newQuery })
  }

  // steelspace tokne
  if (to.query.steelspaceToken) {
    const token = to.query.steelspaceToken.toString()

    const newQuery = to.query
    delete newQuery.steelspaceToken
    await login(token)
    router.replace({ path: to.path, hash: to.hash, query: newQuery })
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const currentUser = getAuth().currentUser

  await loginOnStock()

  if (requiresAuth && currentUser && !authStore.currentOnStockUser) {
    await signOut(getAuth())
    sessionStorage.setItem(redirectUrlKey, to.fullPath)
    next('login')
  }

  if (requiresAuth && !currentUser) {
    // nem login page && nincs bejelentkezve
    sessionStorage.setItem(redirectUrlKey, to.fullPath)
    next('login')
  } else if (!requiresAuth && currentUser && redirectUrl) {
    // login page, be van jelentkezve és van redirect
    sessionStorage.removeItem(redirectUrlKey)
    next(redirectUrl)
  } else if (!requiresAuth && currentUser) {
    // login page és már be van jelentkezve
    next('explorer')
  } else next() // minden más
})

export default router
