import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'

import { NormalizedCacheObject } from 'apollo-boost'
import { ApolloLink } from 'apollo-link'
import { authStore, loginOnStock, setOnStockToken } from './modules/auth'

async function getHeaders() {
  const headers = {}
  const token = authStore.onStockToken
  if (token?.token && new Date(token.expires).getTime() > Date.now()) {
    headers['Authorization'] = `Bearer ${token.token}`
  } else {
    setOnStockToken(null)
    await loginOnStock()
    if (authStore.onStockToken?.token)
      headers['Authorization'] = `Bearer ${authStore.onStockToken?.token}`
  }

  headers['Content-Type'] = 'application/json'

  return headers
}

// Create an http link:
const getHttpLink = async () => {
  const headers = await getHeaders()

  return new HttpLink({
    uri: process.env.VUE_APP_ONSTOCK_API_BASEURL + '/graphql',
    fetch: (uri: RequestInfo, options: RequestInit) => {
      options.headers = headers
      return fetch(uri, options)
    },
  })
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors)
  }
  if (networkError) {
    console.log('networkError', networkError)
  }
})

// Create the apollo client
export const getApolloClient = async (): Promise<ApolloClient<NormalizedCacheObject>> => {
  const httpLink = await getHttpLink()
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, httpLink]),
  })
}
