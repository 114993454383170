export class DtoBase {
  protected payload: Record<string, unknown>

  constructor(obj: Record<string, unknown>) {
    this.payload = obj
  }

  public stringify(): string {
    return JSON.stringify(this.payload)
  }
}
