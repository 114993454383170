import { vuetifyConfig as csuetifyeConfig } from '@consteel/csuetify'
import deepmerge from 'deepmerge'
import { UserVuetifyPreset } from 'vuetify/types/services/presets'

export const vuetifyConfig: Partial<UserVuetifyPreset> = {
  ...deepmerge(csuetifyeConfig, {
    theme: {
      dark: true,
      themes: {
        light: {
          primary: '#006CD4',
        },
        dark: {
          primary: '#5aaeff',
        },
      },
    },
  }),
}
