import { DtoBase } from '../../../types/Dtos/DtoBase'
import { CommentSettings, Position3D } from './comment.model'

export class CreateCommentDto extends DtoBase {
  constructor(
    text: string,
    smadsteelIds: Array<string>,
    position: Position3D | null,
    commentSettings: CommentSettings
  ) {
    super({ text, smadsteelIds, position, commentSettings })
  }
}
