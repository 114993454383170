import { doRequest } from '../../../services/requests/Base'
import { SteelspaceResponse } from '../../../types'
import { CreateOnStockCollaboratorDto, OnStockCollaborator } from '../types'

export const createOnStockCollaboratorRequest = async (
  projectId: string,
  userId: string
): Promise<OnStockCollaborator | undefined> => {
  const dto = new CreateOnStockCollaboratorDto(userId)

  const response = await doRequest(
    `users/me/projects/${projectId}/collaborators`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result: OnStockCollaborator = <OnStockCollaborator>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteOnStockCollaboratorRequest = async (
  projectId: string,
  collaboratorId: string
): Promise<boolean | undefined> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/collaborators/${collaboratorId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}
