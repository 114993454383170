
import { latex_units } from '@/services/unit.service'
import { Get, LoadTransferSurface } from '@consteel/storm'
import DefaultLabel from './DefaultLabel.vue'
import { LabelBase, LabelContent } from './LabelBase'

export default (DefaultLabel as typeof LabelBase).extend({
  name: 'LoadTransferSurfaceLabel',
  computed: {
    currentMember(): LoadTransferSurface | null {
      return Get<LoadTransferSurface>(this.model, this.objectGuid, LoadTransferSurface)
    },
    title(): string | undefined {
      return this.currentMember?.name
    },
    content(): LabelContent[] {
      return [
        {
          name: this.$t('Distribution method'),
          value: this.currentMember?.distributionMethod,
        },
        {
          name: this.$t('Member selection'),
          value: this.currentMember?.memberSelection,
        },
        {
          name: this.$t('Load eccentricity Z'),
          value: this.currentMember?.eccentricity?.z,
          unit: latex_units.mm,
        },
      ]
    },
  },
})
