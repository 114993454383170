import { setOnStockError } from '../onstock.store'
import { exchangeSectionsRequest } from '../requests/onStockSectionExchange.request'
import { SectionChange, SectionExchangeFileResponse } from '../types'

export const exchangeSections = async (
  modelId: string,
  modelFile: File,
  sectionChanges: SectionChange[],
  requiredOutputFiles: string[]
): Promise<SectionExchangeFileResponse[] | undefined> => {
  try {
    const changedFiles = await exchangeSectionsRequest(
      modelId,
      modelFile,
      sectionChanges,
      requiredOutputFiles
    )
    return changedFiles
  } catch (error) {
    setOnStockError('Hiba történt a modell részletek cserélése során')
  }
}
