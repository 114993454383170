import LineLoadLabel from './LineLoadLabel.vue'
import LoadTransferSurfaceLabel from './LoadTransferSurfaceLabel.vue'
import NodalLoadLabel from './NodalLoadLabel.vue'
import StructuralMemberLabel from './StructuralMemberLabel.vue'
import StructuralPlateLabel from './StructuralPlateLabel.vue'
import SupportPointLabel from './SupportPointLabel.vue'
import SurfaceLoadLabel from './SurfaceLoadLabel.vue'

export const AvailableLabelComponents = {
  LineLoadLabel,
  LoadTransferSurfaceLabel,
  NodalLoadLabel,
  StructuralMemberLabel,
  StructuralPlateLabel,
  SupportPointLabel,
  SurfaceLoadLabel,
}
