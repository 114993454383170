var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "x-svg",
      attrs: {
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("line", {
        attrs: {
          x1: "7.25531",
          y1: "16.684",
          x2: "17.4696",
          y2: "6.46968",
          stroke: _vm.color,
          "stroke-width": "1.5",
        },
      }),
      _c("line", {
        attrs: {
          x1: "7.53033",
          y1: "6.46967",
          x2: "17.7446",
          y2: "16.684",
          stroke: _vm.color,
          "stroke-width": "1.5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }