import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const getModelBySteelspaceIdQuery = (steelspaceId: string): DocumentNode => gql`
  query {
    accessibleModels(where: { steelspaceId: { eq: "${steelspaceId}" } }) {
      id
      steelspaceId
      status
      importDate
      projectId
      project {
        id
        name
        description
        collaborators {
          id
          name
          email
          roles
        }
      }
      warehouseOptimizations {
        ${warehouseOptimizationFields}
      }
      supplyOptimizations {
        ${supplyOptimizationFields}
      }
      engineerRules {
        id
        portionSmadsteelId
        portionName
        condition
      }
      managerRules {
        id
        warehouseOptimizations {
          ${warehouseOptimizationFields}
        }
        managerRuleParameters {
          id
          direction
          sectionParameter {
            id
            normalizedName
          }
          step
        }
        materialDirection
        materialStep
        name
        sectionType {
          id
          name
          path
          normalizedName
          sectionFamily {
            id
            name
            normalizedName
          }
          sections {
            id
            normalizedName
            name
          }
        }
      }
      historyItems {
        id
        steelspaceId
        modelId
        warehouseOptimizationId
        supplyOptimizationId
        model {
          projectId
        }
      }
      supplyDefaultLengths {
        id
        length
      }
      supplyDefaultWaste {
        id
        wastePercentage
      }
    }
  }
`

export const getLatestWarehouseOptimizationQuery = (steelspaceId: string): DocumentNode => gql`
  query {
    modelWarehouseOptimizations(
      where: { model: { steelspaceId: { eq: "${steelspaceId}" } } }
      order: { creationDate: DESC }
      first: 1
    ) {
      nodes {
        ${warehouseOptimizationFields}
      }
    }
  }
`

export const getLatestSupplyOptimizationQuery = (steelspaceId: string): DocumentNode => gql`
  query {
    modelSupplyOptimizations(
      where: { model: { steelspaceId: { eq: "${steelspaceId}" } } }
      order: { creationDate: DESC }
      first: 1
    ) {
      nodes {
        ${supplyOptimizationFields}
      }
    }
  }
`

export const getWarehouseOptimizationForHistoryItemQuery = (
  steelspaceId: string,
  firstModelSteelspaceId: string
): DocumentNode => gql`
query {
  modelWarehouseOptimizations(
    where: {
      and: [
        { model: { steelspaceId: { eq: "${steelspaceId}" } } }
        { historyItem: { steelspaceId: { eq: "${firstModelSteelspaceId}" } } }
      ]
    }
  ) {
    nodes {
      ${warehouseOptimizationFields}
    }
  }
}
`

export const getUsersQuery = gql`
  query {
    users {
      id
      status
      name
      email
      roles
      ownedProjects {
        id
      }
    }
  }
`

export const explorerProjectFields = `id
name
description
creationDate
ownerId
owner {
  id
}
collaborators {
  id
  name
  email
  roles
}
owner {
  id
}
models {
  id
  steelspaceId
  status
  importDate
  projectId
  project {
    id
    name
    description
    collaborators {
      id
      name
      email
      roles
    }
  }
}
`
export const warehouseOptimizationFields = `
id
creationDate
modelId
user {
  id
  name
  email
}
matchingMethod
mainPriority
secondaryPriority
mainPriorityOrder
secondaryPriorityOrder
tolerance
warehouseCatalogFileName
warehouseOptimizationPortions {
  id
  portionSmadsteelId
  portionName
  order
  warehouseObjectMatches {
    id
    smadsteelId
    warehouseObject {
      id
      sectionName
      materialName
      length
      tripleCode
      barcode
      remark
      inventoryDate
      section {
        fullName
      }
      material {
        fullName
      }
    }
  }
}
warehouseSummaryItems {
  id
  type
  name
  quantity
  length
  warehouseOptimizationId
  warehouseSummaryItemBlocks(order: {length: DESC}) {
    id
    warehouseSummaryItemId
    length
    quantity
  }
}
historyItem {
  id
  steelspaceId
  modelId
  warehouseOptimizationId
  supplyOptimizationId
  model {
    projectId
  }
}
warehouseObjectMatches {
  id
  smadsteelId
  warehouseObject {
    id
    sectionName
    materialName
    length
    tripleCode
    barcode
    remark
    inventoryDate
    section {
      fullName
    }
    material {
      fullName
    }
  }
}
`

export const supplyOptimizationFields = `
id
creationDate
warehouseOptimization {
  id
}
historyItem {
  id
  steelspaceId
  modelId
  warehouseOptimizationId
  supplyOptimizationId
  model {
    projectId
  }
}
supplySummaryItems {
  id
  sectionName
  materialName
  orderedSupplyLengths {
    id
    length
    wastePercentage
  }
}
optimizationPortions {
  id
  portionSmadsteelId
  portionName
  order
  supplyObjectMatches {
      id
      memberSmadsteelIds
      supplyLength {
          id
          length
          supplySummaryItem {
              id
              sectionName
              materialName
              orderedSupplyLengths {
                  id
                  length
              }
          }
      }
  }
}
objectMatches {
  id
  memberSmadsteelIds
  supplyLength {
    id
    length
    supplySummaryItem {
      id
      sectionName
      materialName
      orderedSupplyLengths {
        id
        length
      }
    }
  }
}
`

export const getCollaboratedProjectsQuery = gql`
  query {
    collaboratedProjects {
    ${explorerProjectFields}
    }
  }
`
export const getOwnedProjectsQuery = gql`
  query {
    myProjects {
      ${explorerProjectFields}
    }
  }
`

export const getMaterialsQuery = (): DocumentNode => gql`
  query {
    materialBanks {
      materials {
        id
        normalizedName
        name
      }
    }
  }
`

export const getLatestSectionBankQuery = (): DocumentNode => gql`
  query {
    sectionBanks(order: { creationDate: ASC }, last: 1) {
      nodes {
        id
        normalizedName
        name
      }
    }
  }
`

export const getSectionOriginsQuery = (bankName: string): DocumentNode => gql`
  query {
    sectionOrigins(bankName: "${bankName}") {
      id
      normalizedName
      name
    }
  }
`

export const getSectionFamiliesQuery = (bankName: string, originName: string): DocumentNode => gql`
  query {
    sectionFamilies(bankName: "${bankName}", originNormalizedName: "${originName}") {
      id
      normalizedName
      name
    }
  }
`

export const getSectionTypesQuery = (
  bankName: string,
  originName: string,
  familyName: string
): DocumentNode => gql`
  query {
    sectionTypes(bankName: "${bankName}", originNormalizedName: "${originName}", familyNormalizedName: "${familyName}") {
      id
      normalizedName
      name
    }
  }
`

export const getSectionsQuery = (
  bankName: string,
  originName: string,
  familyName: string,
  typeName: string,
  endCursor?: string | null
): DocumentNode => {
  const cursor = endCursor ? `after: "${endCursor}"` : ''

  return gql`
    query {
      sections(
        bankName: "${bankName}"
        originNormalizedName: "${originName}"
        familyNormalizedName: "${familyName}"
        typeNormalizedName: "${typeName}"
        first: 50
        ${cursor}
      ) {
        nodes {
          id
          normalizedName
          name
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `
}

export const getSectionParametersQuery = (
  bankName: string,
  originName: string,
  familyName: string,
  typeName: string
): DocumentNode => gql`
  query {
    sectionParameters(
      bankName: "${bankName}"
      originNormalizedName: "${originName}"
      familyNormalizedName: "${familyName}"
      typeNormalizedName: "${typeName}"
    ) {
      id
      normalizedName
      name
    }
  }
`
