import { HubConnection } from '@microsoft/signalr'
import Vue from 'vue'
import { OnStockModel, OnStockProject, OnStockUser, OnStockWarehouseOptimization } from './types'
import { OnStockEngineerRule } from './types/OnStockEngineerRule.model'
import { OnStockManagerRule } from './types/OnStockManagerRules.model'

const state = Vue.observable({
  myProjects: [] as OnStockProject[],
  collaboratedProjects: [] as OnStockProject[],
  project: null as OnStockProject | null,
  onStockModel: null as OnStockModel | null,
  warehouseOptimization: null as OnStockWarehouseOptimization | null,
  warehouseOptimizationSecondary: null as OnStockWarehouseOptimization | null,
  onStockUsers: [] as OnStockUser[],
  progress: 0,
  error: '',
  hub: null as HubConnection | null,
})

export const increaseOnstockProgress = (): void => {
  state.progress += 1
}

export const decreaseOnstockProgress = (): void => {
  state.progress -= 1
}

export const getOnstockLoading = (): boolean => {
  return state.progress !== 0
}

export const onStockStore = state as Readonly<typeof state>

export const setOnStockModelManagerRules = (rules: OnStockManagerRule[]): void => {
  if (state.onStockModel) state.onStockModel.managerRules = rules
}

export const setOnStockModelEngineerRules = (rules: OnStockEngineerRule[]): void => {
  if (state.onStockModel) state.onStockModel.engineerRules = rules
}

export const setMyProjects = (value: OnStockProject[]): void => {
  state.myProjects = value
}

export const setCollaboratedProjects = (value: OnStockProject[]): void => {
  state.collaboratedProjects = value
}

export const setOnStockProject = (value: OnStockProject | null): void => {
  state.project = value
}

export const setOnStockModel = (value: OnStockModel | null): void => {
  state.onStockModel = value
}

export const setWarehouseOptimization = (value: OnStockWarehouseOptimization | null): void => {
  state.warehouseOptimization = value
}

export const setWarehouseOptimizationSecondary = (
  value: OnStockWarehouseOptimization | null
): void => {
  state.warehouseOptimizationSecondary = value
}

export const setOnStockError = (value: string): void => {
  state.error = value
}

export const setOnStockUsers = (onStockUsers: OnStockUser[]): void => {
  state.onStockUsers = onStockUsers
}

export const setOnStockHub = (value: HubConnection | null): void => {
  state.hub = value
}
