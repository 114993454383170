import { DtoBase } from '@/types/Dtos/DtoBase'
import { SectionManagerRuleParameter } from '../OnStockManagerRuleInterfaces'

export class CreateManagerRuleDto extends DtoBase {
  constructor(
    name: string,
    sectionTypeId: number,
    parameters: Array<SectionManagerRuleParameter>,
    materialDirection: number,
    materialStep: number
  ) {
    super({ name, sectionTypeId, parameters, materialDirection, materialStep })
  }
}
