import SprintfFormatter from 'sprintf-formatter'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

export const availableLangs = {
  hu: 'Magyar',
  en: 'English',
  fr: 'français',
  ro: 'Română',
  de: 'Deutsch',
  ru: 'русский',
  sl: 'Slovenščina',
  pl: 'język polski',
  cz: 'český jazyk',
  es: 'Español',
  sk: 'Slovenčina',
  pt: 'Português',
  sr: 'српски језик',
  tr: 'Türkçe',
  cn: '中文',
  bg: 'български език',
  gr: 'ελληνικά',
  it: 'Italiano',
  hr: 'hrvatski jezik',
}

const urlParams = new URL(window.location.href.replace('#/', ''))
const paramLang = urlParams.searchParams.get('lang')

const initialLang = paramLang && paramLang in availableLangs ? paramLang : 'en'

const loadedLanguages: string[] = ['en']

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: initialLang,
  messages: {
    en: require('@/lang/en.json'),
  },
  formatter: new SprintfFormatter(),
})

function setI18nLanguage(lang: string) {
  i18n.locale = lang
  return lang
}

export function loadLanguageAsync(lang: string): Promise<string> {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

loadLanguageAsync(initialLang)
