import { doRequest } from '../../../services/requests/Base'
import { SteelspaceResponse } from '../../../types'
import { CreateFolderDto, MoveFolderDto } from '../types'

export const createFolderRequest = async (
  name: string,
  parentId: string
): Promise<SteelspaceResponse | undefined> => {
  const dto = new CreateFolderDto(name, parentId)
  const response = await doRequest('v2/users/me/folders', 'POST', dto)
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}

export const deleteFolderRequest = async (
  folderId: string
): Promise<SteelspaceResponse | undefined> => {
  const response = await doRequest(`v2/users/me/folders/${folderId}`, 'DELETE')
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}

export const moveFolderRequest = async (
  folderId: string,
  moveTarget: string
): Promise<SteelspaceResponse | undefined> => {
  const dto = new MoveFolderDto(moveTarget)
  const response = await doRequest(`v2/users/me/folders/${folderId}`, 'PUT', dto)
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}
