import { OnStockTokenData, setOnStockError } from '@/modules/onstock'
import { doRequest } from '../../../services/requests'
import { SteelspaceResponse } from '../../../types'
import { logout } from '../auth.service'

export const getLicencingTokenRequest = async (
  authToken: string,
  clientId = process.env.VUE_APP_FB_PROJECTID
): Promise<string> => {
  const response = await fetch(
    `${process.env.VUE_APP_FB_CONSTEEL_LICENCING_BASEURL}/api/v2/oauth/authorize`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ clientId: clientId }),
    }
  )
  const responseJson: string = (await response.json()).payload.token

  return responseJson
}

export const onStockLoginRequest = async (): Promise<OnStockTokenData | undefined> => {
  const response = await doRequest(
    'auth/login',
    'GET',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL
  )

  //Microsoft account authorized by Firestore but not present/authorized in the "insider" database
  if (response.status === 401) {
    setOnStockError(
      'Jelenleg nem rendelkezel OnStock fiókkal. Kérlek vedd fel a kapcsolatot az adminisztrátoroddal a fiók igényléshez.'
    )
    logout(true)
  }

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return (<
      {
        type: string
        expires: string
        token: string
      }
    >(responseJson.payload as unknown)) as OnStockTokenData
  } else {
    return undefined
  }
}

export const getCustomTokenOauthRequest = async (token: string): Promise<string | null> => {
  const response = await fetch(`${process.env.VUE_APP_FB_API_BASEURL}/v2/oauth/token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: token }),
  })
  if (response.status != 200) {
    return null
  }
  const responseJson: string = (await response.json()).payload.customToken
  return responseJson
}

export const generateCustomTokenRequest = async (idToken: string): Promise<string | null> => {
  const response = await fetch(`${process.env.VUE_APP_FB_API_BASEURL}/v2/auth/custom-token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  if (response.status != 200) {
    return null
  }
  const responseJson: string = (await response.json()).payload.customToken
  return responseJson
}
