
import ChevronDownSvg from '@/assets/common/ChevronDown.svg.vue'
import XSvg from '@/assets/common/X.svg.vue'
import { CsFlex, CsGrid, CsLatexField } from '@consteel/csuetify'
import { LabelBase } from './LabelBase'

export default LabelBase.extend({
  name: 'DefaultLabel',
  components: {
    CsFlex,
    CsGrid,
    CsLatexField,
    ChevronDownSvg,
    XSvg,
  },
})
