import ChevronDownSvg from '@/assets/common/ChevronDown.svg.vue'
import XSvg from '@/assets/common/X.svg.vue'
import { CsFlex, IThemeable } from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import Vue, { VueConstructor } from 'vue'
import { TranslateResult } from 'vue-i18n'
import Themeable from 'vuetify/lib/mixins/themeable'
import { modelViewerStore } from '../../../store/modelViewer.store'

export type LabelContent = {
  name: string | TranslateResult | undefined
  value: string | number | undefined
  unit?: string | undefined
}

export const LabelBase = (Vue as VueConstructor<IThemeable>).extend({
  components: {
    CsFlex,
    ChevronDownSvg,
    XSvg,
  },
  mixins: [Themeable],
  props: {
    objectGuid: {
      type: String,
      required: true,
    },
    visibility: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      open: true,
    }
  },
  computed: {
    model(): Model {
      // Itt már elvileg mindig van model
      return modelViewerStore.model.rawSmadsteelModel
        ? modelViewerStore.model.rawSmadsteelModel
        : ({} as Model)
    },
    scale(): number {
      return modelViewerStore.scaleOfLables
    },
    content() {
      return [{ name: '', value: 'This label is not implemented!', unit: '' }]
    },
    title() {
      return 'Default label'
    },
  },
})
