import { getApp } from '@firebase/app'
import { getAuth } from '@firebase/auth'
import { collection, getDocs, getFirestore, onSnapshot, query, where } from '@firebase/firestore'
import { sendRequestMoreEmailRequest } from './requests'
import {
  setBandwidthLimitation,
  setBandwidthUsage,
  setUserServiceLoading,
  setUserServiceUnSubs,
  userServiceStore as state,
} from './userService.store'

/////////////////////////
//////API REQUESTS///////
/////////////////////////
export const sendRequestMoreEmail = async (): Promise<void> => {
  setUserServiceLoading(true)
  await sendRequestMoreEmailRequest()
  setUserServiceLoading(false)
}

/////////////////////////
/////FIREBASE SDK//////
////////////////////////
const queryLimit = async (): Promise<number> => {
  setUserServiceLoading(true)
  let bandwidthLimit = 0
  const serviceLimitations = await getDocs(
    collection(getFirestore(getApp()), `users/${getAuth().currentUser?.uid}/serviceLimitations`)
  )

  serviceLimitations.forEach((doc) => {
    const data = doc.data()

    if (data.serviceName === 'steelspace_bandwidth') {
      bandwidthLimit = data.limit
    }
  })

  setUserServiceLoading(false)

  return bandwidthLimit
}

const queryUsage = async (): Promise<number> => {
  setUserServiceLoading(true)
  let bandwidthUsage = 0

  const serviceUsagesQuery = query(
    collection(getFirestore(getApp()), `users/${getAuth().currentUser?.uid}/serviceUsages`),
    where('month', '==', new Date().getMonth() + 1),
    where('year', '==', new Date().getFullYear())
  )

  const serviceUsages = await getDocs(serviceUsagesQuery)

  serviceUsages.forEach((doc) => {
    const data = doc.data()

    if (data.serviceName === 'steelspace_bandwidth') {
      bandwidthUsage = data.usage
    }
  })

  setUserServiceLoading(false)

  return bandwidthUsage
}

const subscribeToLimit = (): void => {
  const unsubKey = 'limit'

  if (state.unSubs[unsubKey] || !getAuth().currentUser) {
    return
  }

  const unsubFromBandwidthLimit = onSnapshot(
    query(
      collection(getFirestore(getApp()), `users/${getAuth().currentUser?.uid}/serviceLimitations`)
    ),
    (docs) =>
      docs.forEach((doc) => {
        const data = doc.data()
        if (data.serviceName === 'steelspace_bandwidth') {
          setBandwidthLimitation(data.limit)
        }
      })
  )

  state.unSubs[unsubKey] = unsubFromBandwidthLimit
}

const subscribeToUsage = (): void => {
  const unsubKey = 'usage'

  if (state.unSubs['usage'] || !getAuth().currentUser) {
    return
  }

  const unsubFromBandwidthUsage = onSnapshot(
    query(
      collection(getFirestore(getApp()), `users/${getAuth().currentUser?.uid}/serviceUsages`),
      where('month', '==', new Date().getMonth() + 1),
      where('year', '==', new Date().getFullYear())
    ),
    (docs) =>
      docs.forEach((doc) => {
        const data = doc.data()
        if (data.serviceName === 'steelspace_bandwidth') {
          setBandwidthUsage(data.usage)
        }
      })
  )

  state.unSubs[unsubKey] = unsubFromBandwidthUsage
}

export const getBandwidthLimitationAsync = async (): Promise<number> => {
  if (!state.bandwidthLimitation) return await queryLimit()

  return state.bandwidthLimitation
}

export const getBandwidthLimitationSubscribe = (): number => {
  if (!state.bandwidthLimitation) subscribeToLimit()

  return state.bandwidthLimitation
}

export const getBandwidthUsageAsync = async (): Promise<number> => {
  if (!state.bandwidthUsage) return await queryUsage()

  return state.bandwidthUsage
}

export const getBandwidthUsageSubscribe = (): number => {
  if (!state.bandwidthUsage) subscribeToUsage()

  return state.bandwidthUsage
}

export const unSubFromBandwidthFirebaseSnapshots = (): void => {
  Object.values(state.unSubs).forEach((unsub) => {
    unsub()
  })

  setUserServiceUnSubs({})
}
