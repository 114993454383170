import { SteelspaceResponse } from '@/types'
import { doRequest } from '../../../services/requests/Base'
import {
  Comment,
  CommentResponse,
  CommentSettings,
  ModifyCommentDto,
  Position3D,
  Unread,
} from '../types'
import { CreateCommentDto } from '../types/CreateCommentDto'

export const fetchCommentsRequest = async (
  modelId: string,
  historyId?: string
): Promise<Comment[] | undefined> => {
  let response: Response
  if (historyId) {
    response = await doRequest(`v2/users/me/models/${modelId}/history/${historyId}/comments`, 'GET')
  } else {
    response = await doRequest(`v2/users/me/models/${modelId}/comments`, 'GET')
  }
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: Comment[] = <Comment[]>responseJson.payload.comments
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const fetchResponsesRequest = async (
  modelId: string,
  commentId: string
): Promise<CommentResponse[] | undefined> => {
  const response = await doRequest(
    `v2/users/me/models/${modelId}/comments/${commentId}/responses`,
    'GET'
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: CommentResponse[] = <CommentResponse[]>responseJson.payload.responses
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const fetchUnreadsRequest = async (
  modelId: string,
  historyId?: string
): Promise<Unread[] | undefined> => {
  let response: Response
  if (historyId) {
    response = await doRequest(`v2/users/me/models/${modelId}/history/${historyId}/unreads`, 'GET')
  } else {
    response = await doRequest(`v2/users/me/models/${modelId}/unreads`, 'GET')
  }
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: Unread[] = <Unread[]>responseJson.payload.unreads
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const createCommentRequest = async (
  text: string,
  modelId: string,
  commentSettings: CommentSettings,
  smadsteelIds?: string[],
  historyId?: string,
  position?: Position3D
): Promise<Comment | undefined> => {
  const dto = new CreateCommentDto(text, smadsteelIds || [], position || null, commentSettings)
  let response: Response | undefined
  if (historyId) {
    response = await doRequest(
      `v2/users/me/models/${modelId}/history/${historyId}/comments`,
      'POST',
      dto
    )
  } else {
    response = await doRequest(`v2/users/me/models/${modelId}/comments`, 'POST', dto)
  }
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 201) {
    const result: Comment = <Comment>responseJson.payload.comment
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const createResponseRequest = async (
  modelId: string,
  commentId: string,
  text: string
): Promise<CommentResponse | undefined> => {
  const dto = new ModifyCommentDto(text)
  const response = await doRequest(
    `v2/users/me/models/${modelId}/comments/${commentId}/responses`,
    'POST',
    dto
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 201) {
    const result: CommentResponse = <CommentResponse>responseJson.payload.response
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const updateCommentRequest = async (
  modelId: string,
  commentId: string,
  commentText: string,
  historyId?: string
): Promise<Comment | undefined> => {
  const dto = new ModifyCommentDto(commentText)
  let response: Response
  if (historyId) {
    response = await doRequest(
      `v2/users/me/models/${modelId}/history/${historyId}/comments/${commentId}`,
      'PUT',
      dto
    )
  } else {
    response = await doRequest(`v2/users/me/models/${modelId}/comments/${commentId}`, 'PUT', dto)
  }
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: Comment = <Comment>responseJson.payload.modifiedComment
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const updateResponseRequest = async (
  modelId: string,
  commentId: string,
  responseId: string,
  commentText: string
): Promise<CommentResponse | undefined> => {
  const dto = new ModifyCommentDto(commentText)
  const response = await doRequest(
    `v2/users/me/models/${modelId}/comments/${commentId}/responses/${responseId}`,
    'PUT',
    dto
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: CommentResponse = <CommentResponse>responseJson.payload.modifiedResponse
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteCommentRequest = async (
  modelId: string,
  commentId: string,
  historyId?: string
): Promise<Comment | undefined> => {
  let response: Response
  if (historyId) {
    response = await doRequest(
      `v2/users/me/models/${modelId}/history/${historyId}/comments/${commentId}`,
      'DELETE'
    )
  } else {
    response = await doRequest(`v2/users/me/models/${modelId}/comments/${commentId}`, 'DELETE')
  }
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return new Comment(responseJson.payload)
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteResponseRequest = async (
  modelId: string,
  commentId: string,
  responseId: string
): Promise<CommentResponse | undefined> => {
  const response = await doRequest(
    `v2/users/me/models/${modelId}/comments/${commentId}/responses/${responseId}`,
    'DELETE'
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return new CommentResponse(responseJson.payload)
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteUnreadRequest = async (
  modelId: string,
  commentId: string,
  historyId?: string
): Promise<Unread | undefined> => {
  let response: Response
  if (historyId) {
    response = await doRequest(
      `v2/users/me/models/${modelId}/history/${historyId}/unreads/${commentId}`,
      'DELETE'
    )
  } else {
    response = await doRequest(`v2/users/me/models/${modelId}/unreads/${commentId}`, 'DELETE')
  }
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return new Unread(responseJson.payload)
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}
