import { HistoryItem } from '@/modules/history'
import { SteelspaceModel } from '@/modules/model'
import Dexie, { IndexableType, Table } from 'dexie'
import { CachedHistoryItem, CachedModel } from '../types'

export type previewUrlStorageRecord = {
  previewUrl: string
  expires: number
  lastModificationDate: number
}

export const getModelPreviewUrlFromCache = (model: SteelspaceModel): string | void => {
  const item = localStorage.getItem(model.id)

  if (item) {
    const jsonItem = JSON.parse(item) as previewUrlStorageRecord
    if (
      jsonItem.expires > Date.now() &&
      jsonItem.lastModificationDate === model.lastModificationDate
    ) {
      return jsonItem.previewUrl
    }
  }
}

export const getHistoryPreviewUrlFromCache = (history: HistoryItem): string | void => {
  const item = localStorage.getItem(history.id)

  if (item) {
    const jsonItem = JSON.parse(item) as previewUrlStorageRecord
    if (jsonItem.expires > Date.now()) {
      return jsonItem.previewUrl
    }
  }
}

export const saveHistoryPreviewUrlToCache = (historyItem: HistoryItem): void => {
  localStorage.setItem(
    historyItem.id,
    JSON.stringify({
      previewUrl: historyItem.previewUrl,
      expires: Date.now() + 3600 * 1000 * 23.9,
    } as previewUrlStorageRecord)
  )
}

export const saveModelPreviewUrlToCache = (model: SteelspaceModel): void => {
  localStorage.setItem(
    model.id,
    JSON.stringify({
      previewUrl: model.previewUrl,
      expires: Date.now() + 3600 * 1000 * 23.9,
      lastModificationDate: model.lastModificationDate,
    } as previewUrlStorageRecord)
  )
}

export const deleteExpiredPreviewsFromCache = (): void => {
  Object.entries(localStorage).map((item) =>
    item[1].expires
      ? item[1].expires < Date.now()
        ? localStorage.removeItem(item[0])
        : null
      : null
  )
}

export const deleteModelFromCache = async (modelId: string): Promise<boolean | undefined> => {
  await db.models.delete(modelId)

  return true
}

export const getModelFromCache = async (
  modelId: string,
  lastModificationDate: number
): Promise<CachedModel | undefined> => {
  const cached = await db.models.get(modelId)
  if (cached && cached.lastModificationDate < lastModificationDate) {
    return undefined
  }
  return cached
}

export const getHistoryFromCache = async (
  historyId: string
): Promise<CachedHistoryItem | undefined> => {
  const cached = await db.historyItems.get(historyId)

  if (!cached) {
    return undefined
  }

  return cached
}

export const saveModelToCache = async (
  modelId: string,
  lastModificationDate: number,
  file: Blob
): Promise<void> => {
  await db.models.put({
    id: modelId,
    lastModificationDate,
    file,
  })
}

export const saveHistoryToCache = async (id: string, file: Blob): Promise<void> => {
  await db.historyItems.put({
    id,
    file,
  })
}

class DexieDB extends Dexie {
  models!: Table<CachedModel>
  historyItems!: Table<CachedHistoryItem>

  constructor() {
    super('steelspace')
    this.version(1).stores({
      models: '++id',
    })
    this.version(2).stores({
      historyItems: '++id',
    })
  }
}

const db = new DexieDB()

export const getKeys = async (): Promise<IndexableType[]> =>
  await db.models.toCollection().primaryKeys()
