import { ParameterDirections, ParameterNames } from '../enums'

export const ParameterNamesMap: Record<ParameterNames, string> = {
  HEIGHT: 'Magasság',
  THICKNESS: 'Vastagság',
  WIDTH: 'Szélesség',
  DIAMETER: 'Átmérő',
  MATERIAL: 'Anyagminőség',
}

export const ParameterDirectionsMap: Record<string, number> = {
  DOWN: ParameterDirections.Down,
  UP: ParameterDirections.Up,
  UP_AND_DOWN: ParameterDirections.UpAndDown,
}
