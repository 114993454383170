
import { commentStore } from '@/modules/comment'
import { resetErrorState } from '@/services'
import Vue from 'vue'
import { authStore } from '../../modules/auth'
import { folderStore } from '../../modules/folder'
import { historyStore } from '../../modules/history'
import { modelStore } from '../../modules/model'
import { onStockStore } from '../../modules/onstock'
import { userServiceStore } from '../../modules/userService'

export default Vue.extend({
  name: 'Snackbar',
  computed: {
    snackbarMessage(): string {
      return (
        authStore.error ||
        folderStore.error ||
        historyStore.historyErrorMessage ||
        modelStore.error ||
        onStockStore.error ||
        userServiceStore.error ||
        commentStore.error
      )
    },
    showSnackBar: {
      get() {
        return Boolean(
          authStore.error ||
            folderStore.error ||
            historyStore.historyErrorMessage ||
            modelStore.error ||
            onStockStore.error ||
            userServiceStore.error ||
            commentStore.error
        )
      },
      set() {
        resetErrorState()
      },
    },
  },
})
