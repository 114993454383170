import JSZip from 'jszip'
import { doRequest } from '../../../services/requests/Base'
import { SectionChange, SectionExchangeFileResponse } from '../types'

export const exchangeSectionsRequest = async (
  modelId: string,
  modelFile: File,
  sectionChanges: SectionChange[],
  requiredOutputFiles: string[]
): Promise<SectionExchangeFileResponse[] | undefined> => {
  const formData = new FormData()
  formData.append('Model', modelFile)
  formData.append('SectionChanges', JSON.stringify(sectionChanges))
  requiredOutputFiles.forEach((requiredOutputFile) =>
    formData.append('RequiredOutputFiles[]', requiredOutputFile)
  )

  const response = await doRequest(
    `api/section-exchange/models/${modelId}`,
    'POST',
    formData,
    process.env.VUE_APP_SECTION_EXCHANGE_API_BASEURL,
    true,
    false
  )

  if (response.ok) {
    const zipData = await response.blob()
    const zip = new JSZip()

    try {
      const unzippedFiles = await zip.loadAsync(zipData)
      const modelFiles: SectionExchangeFileResponse[] = []

      for (const [, file] of Object.entries(unzippedFiles.files) as [string, JSZip.JSZipObject][]) {
        const fileContent = await file.async('arraybuffer')
        modelFiles.push({
          file: new Blob([fileContent], { type: 'application/octet-stream' }),
          fileName: file.name,
        } as SectionExchangeFileResponse)
      }

      return modelFiles
    } catch (error) {
      throw new Error(('Error unzipping files: ' + error) as string)
    }
  } else {
    throw new Error((await response.text()) as string)
  }
}
