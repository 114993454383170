import { Dof } from '@consteel/storm/src/Models/Release/Dof'

export const latex_units = {
  _c: '1/C°',
  deg: '°',
  kg_m3: 'kg/m^3',
  kn_m: 'kN/m',
  kn_m2: 'kN/m^2',
  kn_mm: 'kN/mm',
  kn: 'kN',
  knm_rad: 'kNm/rad',
  knm: 'kNm',
  knm2__rad_m: '\\frac{kNm^2}{rad / m}',
  m: 'm',
  m2: 'm^2',
  mm: 'mm',
  mm2: 'mm^2',
  mpa: 'MPa',
  n_mm2: 'N/mm^2',
  t: 't',
}

export function frac(a: string | number, b: string | number): string {
  return `${sup(a)}&frasl;${sub(b)}`
}

export function sub(item: string | number): string {
  return `<sub>${item}</sub>`
}

export function sup(item: string | number): string {
  return `<sup>${item}</sup>`
}

export function roundToTwoDigits(item: number | undefined): string {
  if (item === undefined) {
    return ''
  }
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(item)
}

export function roundToTwoSignificantDigits(item: number | undefined): string {
  if (item === undefined) {
    return ''
  }
  return new Intl.NumberFormat(undefined, {
    minimumSignificantDigits: 2,
    maximumSignificantDigits: 2,
  }).format(item)
}

export function dofToValue(dof: Dof | undefined): string | number | undefined {
  if (!dof) return undefined

  if (dof.type === 'Fix' || dof.type === 'Free' || dof.type === 'Continous') {
    return dof.type
  }

  return roundToTwoDigits(dof.value)
}

export function IsDofNumeric(dof: string | number | undefined): boolean {
  if (typeof dof === 'number') {
    return true
  }

  return false
}
