var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "chevron-down-svg",
      attrs: {
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11 14.0817V14.0817L17.2224 8.1103C17.376 7.9603 17.6297 7.96387 17.7868 8.11745L18.8799 9.1853C19.037 9.33887 19.0406 9.58888 18.887 9.73888L11.2893 17.0317C11.2107 17.1103 11.1036 17.146 11 17.1389C10.8928 17.1424 10.7893 17.1067 10.7107 17.0317L3.113 9.73888C2.9594 9.58888 2.96298 9.33887 3.12014 9.1853L4.21318 8.11745C4.37035 7.96387 4.62396 7.9603 4.77756 8.1103L11 14.0817V14.0817Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }