export enum SelectorOptions {
  Section,
  Material,
  None,
}

export enum ParameterNames {
  HEIGHT = 'HEIGHT',
  THICKNESS = 'THICKNESS',
  WIDTH = 'WIDTH',
  DIAMETER = 'DIAMETER',
  MATERIAL = 'MATERIAL',
}

export enum ParameterDirections {
  Up = 0,
  Down = 1,
  UpAndDown = 2,
}

export enum ParameterSteps {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Default = 1,
}

export enum OpenOptions {
  ReadOnly = 0,
  Write = 1,
  Edit = 2,
}
