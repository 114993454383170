export enum EngineerRuleCondition {
  MaterialAndSectionChangeAllowed = 'MATERIAL_AND_SECTION_CHANGE_ALLOWED',
  OnlyMaterialChangeAllowed = 'ONLY_MATERIAL_CHANGE_ALLOWED',
  NoChangeAllowed = 'NO_CHANGE_ALLOWED',
}

export type OnStockEngineerRule = {
  id: number
  portionSmadsteelId: string
  portionName: string
  condition: EngineerRuleCondition
}
