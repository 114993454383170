import { guid } from '@consteel/storm/src/Models/ModelBase'

export interface Position3D {
  x: number
  y: number
  z: number
}

export interface CommentSettings {
  xRayToggle: boolean
  loadScale: {
    active: boolean
    scale: number
  }
  supportScale: {
    active: boolean
    scale: number
  }
  labelScale: {
    active: boolean
    scale: number
  }
  commentScale: {
    active: boolean
    scale: number
  }
  clipPlane: {
    active: boolean
    position: Position3D
  }
  camera: {
    alpha: number
    beta: number
    radius: number
    position: Position3D | null
    target: Position3D | null
  }
}

export interface CommentUserData {
  id: string
  email: string
  name: string
}

export interface CommentTreeNode {
  id: string
  email: string
  userName: string
  role: string
  replyCnt?: number
  timestampInMS: number
  comment: string
  children?: CommentTreeNode[]
}

export class Comment {
  id = ''
  userData = {} as CommentUserData
  text = ''
  creationDate = 0
  updateDate = 0
  modelId = ''
  modelName = ''
  historyId = ''
  responseCount = 0
  smadsteelIds = [] as guid[]
  position?: Position3D
  commentSettings: CommentSettings = {
    xRayToggle: false,
    loadScale: {
      active: false,
      scale: 0,
    },
    supportScale: {
      active: false,
      scale: 0,
    },
    labelScale: {
      active: false,
      scale: 0,
    },
    commentScale: {
      active: false,
      scale: 0,
    },
    clipPlane: {
      active: false,
      position: { x: 0, y: 0, z: 0 } as Position3D,
    },
    camera: {
      alpha: 0,
      beta: 0,
      radius: 0,
      position: null,
      target: null,
    },
  }

  constructor(obj?: Record<string, unknown> | Comment) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}

export class CommentResponse {
  id = ''
  userData = {} as CommentUserData
  text = ''
  creationDate = 0
  updateDate = 0

  constructor(obj?: Record<string, unknown>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}

export class Unread {
  id = ''
  userData = {} as CommentUserData
  modelId = ''
  modelName = ''
  historyId = ''
  commentId = ''

  constructor(obj?: Record<string, unknown>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
