import { setAuthError } from '@/modules/auth'
import {
  resetCommentState,
  setCommentError,
  unSubFromCommentFirebaseSnapshots,
} from '@/modules/comment'
import { setOnStockError } from '@/modules/onstock'
import {
  resetFolderState,
  setFolderError,
  unSubFromFolderFirebaseSnapshots,
} from '../modules/folder'
import {
  resetHistoryError,
  resetHistoryState,
  unSubFromHistoryFirebaseSnapshots,
} from '../modules/history'
import { resetModelState, setModelError, unSubFromModelFirebaseSnapshots } from '../modules/model'
import {
  resetUserServiceState,
  setUserServiceError,
  unSubFromBandwidthFirebaseSnapshots,
} from '../modules/userService'

export const unSubFromFirebaseSnapshots = (): void => {
  unSubFromBandwidthFirebaseSnapshots()
  unSubFromFolderFirebaseSnapshots()
  unSubFromHistoryFirebaseSnapshots()
  unSubFromModelFirebaseSnapshots()
  unSubFromCommentFirebaseSnapshots()
}

export const resetFirebaseState = (): void => {
  resetUserServiceState()
  resetModelState()
  resetHistoryState()
  resetFolderState()
  resetCommentState()
}

export const resetErrorState = (): void => {
  setAuthError('')
  setCommentError('')
  setFolderError('')
  resetHistoryError()
  setModelError('')
  setOnStockError('')
  setUserServiceError('')
}
