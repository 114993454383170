import { doRequest } from '../../../services/requests'
import { SteelspaceResponse } from '../../../types'
import { UpdateOnStockEngineerRuleDto } from '../types'
import { EngineerRuleCondition, OnStockEngineerRule } from '../types/OnStockEngineerRule.model'
import { CreateOnStockEngineerRuleDto } from '../types/dtos/CreateOnStockEngineerRuleDto'

export const createOnStockEngineerRuleRequest = async (
  projectId: number,
  modelId: number,
  portionSmadsteelId: string,
  portionName: string,
  condition: EngineerRuleCondition
): Promise<OnStockEngineerRule | undefined> => {
  const dto = new CreateOnStockEngineerRuleDto(portionSmadsteelId, portionName, condition)

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/engineer`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    const result: OnStockEngineerRule = <OnStockEngineerRule>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const updateOnStockEngineerRuleRequest = async (
  projectId: number,
  modelId: number,
  ruleId: number,
  portionSmadsteelId: string,
  portionName: string,
  condition: EngineerRuleCondition
): Promise<OnStockEngineerRule | undefined> => {
  const dto = new UpdateOnStockEngineerRuleDto(portionSmadsteelId, portionName, condition)

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/engineer/${ruleId}`,
    'PUT',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result: OnStockEngineerRule = <OnStockEngineerRule>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteOnStockEngineerRuleRequest = async (
  projectId: number,
  modelId: number,
  ruleId: number
): Promise<boolean | undefined> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/engineer/${ruleId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}
