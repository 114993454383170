import { formatSize } from '../../../services'
import { ModelUserData } from '../../model/types/ModelItem.model'

export type FolderType = 'shared' | 'own' | 'public'

export class SteelspaceFolder {
  id = ''
  creationDate = 0
  lastOpenDate = 0
  name = ''
  type = '' as FolderType
  description = ''
  size = 0
  displaySize = ''
  isRoot = false
  parentId = ''
  parentName = ''
  userData = {} as ModelUserData

  constructor(obj?: Record<string, unknown>) {
    if (obj) {
      Object.assign(this, obj)
    }

    this.displaySize = formatSize(this.size)
  }
}
