import { doRequest } from '../../../services/requests/Base'
import { SteelspaceResponse } from '../../../types'
import { CreateOnStockUserDto, OnStockPermission, OnStockUser } from '../types'

export const createOnStockUserRequest = async (
  roles: Array<OnStockPermission>,
  email: string
): Promise<OnStockUser | undefined> => {
  const dto = new CreateOnStockUserDto(roles, email)

  const response = await doRequest(
    'users',
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result: OnStockUser = <OnStockUser>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteOnStockUserRequest = async (id: string): Promise<boolean | undefined> => {
  const response = await doRequest(
    `users/${id}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const getOnStockUsersRequest = async (): Promise<OnStockUser[] | undefined> => {
  const response = await doRequest(
    `users`,
    'GET',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )
  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return (<OnStockUser[]>(responseJson.payload as unknown)) as OnStockUser[]
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const getOnStockCurrentUserRequest = async (): Promise<OnStockUser | undefined> => {
  const response = await doRequest(
    `auth/me`,
    'GET',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )
  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return (<OnStockUser>responseJson.payload) as OnStockUser
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}
