
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { Get, NodalLoad } from '@consteel/storm'
import DefaultLabel from './DefaultLabel.vue'
import { LabelBase, LabelContent } from './LabelBase'

export default (DefaultLabel as typeof LabelBase).extend({
  name: 'NodalLoadLabel',
  computed: {
    currentMember(): NodalLoad | null {
      return Get<NodalLoad>(this.model, this.objectGuid, NodalLoad)
    },
    title(): string | undefined {
      return this.currentMember?.name
    },
    multiplicationFactor(): number {
      return this.currentMember?.multiplicationFactor || 1
    },
    content(): LabelContent[] {
      return [
        {
          name: 'X',
          value: roundToTwoDigits(
            this.currentMember?.force ? this.currentMember.force.x * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn,
        },
        {
          name: 'Y',
          value: roundToTwoDigits(
            this.currentMember?.force ? this.currentMember.force.y * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn,
        },
        {
          name: 'Z',
          value: roundToTwoDigits(
            this.currentMember?.force ? this.currentMember.force.z * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn,
        },
        {
          name: 'Mx',
          value: roundToTwoDigits(
            this.currentMember?.moment
              ? this.currentMember.moment.xx * this.multiplicationFactor
              : 0
          ),
          unit: latex_units.knm,
        },
        {
          name: 'My',
          value: roundToTwoDigits(
            this.currentMember?.moment
              ? this.currentMember.moment.yy * this.multiplicationFactor
              : 0
          ),
          unit: latex_units.knm,
        },
        {
          name: 'Mz',
          value: roundToTwoDigits(
            this.currentMember?.moment
              ? this.currentMember.moment.zz * this.multiplicationFactor
              : 0
          ),
          unit: latex_units.knm,
        },
      ]
    },
  },
})
