import {
  DocumentData,
  Query,
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from '@firebase/firestore'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { ModelStateNumber } from '../../store/modelViewer.store'
import { authStore } from '../auth'
import { updateHistoryItemCommentCountInStore } from '../history'
import {
  commentStore,
  decreaseCommentProgress,
  increaseCommentProgress,
  setCommentComments,
  setCommentError,
  setCommentResponses,
  setCommentUnSubs,
  setCommentUnreads,
  setCommentsBasedOnModelCompareState,
} from './comment.store'
import {
  createCommentRequest,
  createResponseRequest,
  deleteCommentRequest,
  deleteResponseRequest,
  deleteUnreadRequest,
  fetchCommentsRequest,
  fetchResponsesRequest,
  fetchUnreadsRequest,
  updateCommentRequest,
  updateResponseRequest,
} from './requests'
import {
  Comment,
  CommentResponse,
  CommentSettings,
  CommentTreeNode,
  Position3D,
  Unread,
} from './types'

/////////////////////////
/////REQUEST CALLS///////
/////////////////////////

export const fetchComments = async (
  modelId: string,
  historyId?: string,
  compareState = ModelStateNumber.primary
): Promise<void> => {
  increaseCommentProgress()

  try {
    const response = await fetchCommentsRequest(modelId, historyId)

    if (!response) {
      setCommentsBasedOnModelCompareState([], compareState)
      return
    }
    if (response) setCommentsBasedOnModelCompareState(response, compareState)

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const fetchResponses = async (modelId: string, commentId: string): Promise<void> => {
  increaseCommentProgress()

  try {
    const response = await fetchResponsesRequest(modelId, commentId)
    if (!response) return
    setCommentResponses({ ...commentStore.responses, [commentId]: response })

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const fetchUnreads = async (modelId: string, historyId?: string): Promise<void> => {
  increaseCommentProgress()

  try {
    const response = await fetchUnreadsRequest(modelId, historyId)

    if (!response) {
      setCommentUnreads([])
      decreaseCommentProgress()
      return
    }
    setCommentUnreads(response)
    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const createResponse = async (
  modelId: string,
  commentId: string,
  responseText: string
): Promise<void> => {
  increaseCommentProgress()

  try {
    const response = await createResponseRequest(modelId, commentId, responseText)

    if (!response) {
      decreaseCommentProgress()
      return
    }
    // fetch responses
    const commentResponses = commentStore.responses[commentId]
    let newResponses: Record<string, CommentResponse[]> = {}

    if (commentResponses) {
      newResponses = {
        ...commentStore.responses,
        [commentId]: [...commentStore.responses[commentId], response],
      }
    } else {
      newResponses = {
        ...commentStore.responses,
        [commentId]: [response],
      }
    }
    setCommentResponses(newResponses)
    updateCommentReplyCntInStore(commentId, 1)

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const createComment = async (
  text: string,
  modelId: string,
  commentSettings: CommentSettings,
  historyId?: string,
  smadsteelIds?: string[],
  position?: Position3D
): Promise<void> => {
  increaseCommentProgress()

  try {
    const response = await createCommentRequest(
      text,
      modelId,
      commentSettings,
      smadsteelIds,
      historyId,
      position
    )

    if (!response) {
      decreaseCommentProgress()
      return
    }
    setCommentComments([...commentStore.comments, response])
    if (historyId) updateHistoryItemCommentCountInStore(historyId, 1)

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const updateComment = async (
  modelId: string,
  commentId: string,
  commentText: string,
  historyId?: string
): Promise<void> => {
  increaseCommentProgress()

  try {
    await updateCommentRequest(modelId, commentId, commentText, historyId)
    setCommentComments(
      commentStore.comments.map((comment) => {
        if (comment.id === commentId) return { ...comment, text: commentText }
        return { ...comment }
      })
    )

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const updateResponse = async (
  modelId: string,
  commentId: string,
  responseId: string,
  commentText: string
): Promise<void> => {
  increaseCommentProgress()

  try {
    await updateResponseRequest(modelId, commentId, responseId, commentText)
    const newResponsesRecord: Record<string, CommentResponse[]> = {}
    for (const key in commentStore.responses) {
      if (key === commentId) {
        newResponsesRecord[key] = commentStore.responses[key].map((response) => {
          if (response.id === responseId) return { ...response, text: commentText }
          return { ...response }
        })
      } else {
        newResponsesRecord[key] = commentStore.responses[key]
      }
    }
    setCommentResponses(newResponsesRecord)

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const deleteComment = async (
  modelId: string,
  commentId: string,
  historyId?: string
): Promise<void> => {
  increaseCommentProgress()

  try {
    await deleteCommentRequest(modelId, commentId, historyId)
    setCommentComments(commentStore.comments.filter((comment) => comment.id !== commentId))
    if (historyId) updateHistoryItemCommentCountInStore(historyId, -1)

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const deleteResponse = async (
  modelId: string,
  commentId: string,
  responseId: string
): Promise<void> => {
  increaseCommentProgress()

  try {
    await deleteResponseRequest(modelId, commentId, responseId)
    const newResponsesRecord: Record<string, CommentResponse[]> = {}
    for (const key in commentStore.responses) {
      if (key === commentId) {
        newResponsesRecord[key] = commentStore.responses[key].filter((response) => {
          return response.id !== responseId
        })
      } else {
        newResponsesRecord[key] = commentStore.responses[key]
      }
    }
    setCommentResponses(newResponsesRecord)
    updateCommentReplyCntInStore(commentId, -1)

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

export const deleteUnread = async (
  modelId: string,
  commentId: string,
  historyId?: string
): Promise<void> => {
  increaseCommentProgress()

  try {
    await deleteUnreadRequest(modelId, commentId, historyId)
    setCommentUnreads(commentStore.unreads.filter((unread) => unread.commentId !== commentId))

    decreaseCommentProgress()
  } catch (error) {
    decreaseCommentProgress()

    if (error instanceof Error) setCommentError(error.message)
  }
}

///////////////////
/////OTHERS///////
///////////////////

export const sortCommentsByDate = (comments: CommentTreeNode[]): void => {
  comments.sort((a: CommentTreeNode, b: CommentTreeNode) => {
    return +new Date(a.timestampInMS) - +new Date(b.timestampInMS)
  })
}

export const updateCommentReplyCntInStore = (commentId: string, modifier: number): void => {
  setCommentComments(
    commentStore.comments.map((comment) => {
      if (comment.id === commentId)
        return new Comment({ ...comment, responseCount: comment.responseCount + modifier })
      return comment
    })
  )
}

/////////////////////////
/////FIREBASE SDK//////
////////////////////////

export const getUnreadsSubscribe = (modelId: string, historyId?: string): void => {
  subscribeToUnreads(modelId, historyId)
}

const subscribeToUnreads = (modelId: string, historyId?: string): void => {
  const unsubKey = 'unreads'
  const currentUser = getAuth().currentUser

  if (commentStore.unSubs[unsubKey] || !currentUser) {
    return
  }

  const queryUnreads: Query<DocumentData> = query(
    collection(getFirestore(getApp()), 'unreads'),
    where('modelId', '==', modelId),
    where('historyId', '==', historyId || ''),
    where('userData.id', '==', currentUser.uid)
  )

  const unsubFromUnreadsUpdates = onSnapshot(queryUnreads, async (querySnapshot) => {
    increaseCommentProgress()
    const promises = querySnapshot.docs.map(async (doc) => {
      const unread = new Unread(doc.data())
      return unread
    })

    const unreads = await Promise.all(promises)
    setCommentUnreads(unreads)

    decreaseCommentProgress()
  })

  commentStore.unSubs[unsubKey] = unsubFromUnreadsUpdates
}

export const unSubFromCommentFirebaseSnapshots = (): void => {
  Object.values(commentStore.unSubs).forEach((unsub) => {
    unsub()
  })

  setCommentUnSubs({})
}
